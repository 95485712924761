* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background: #ecedef !important;
}

.ui.segment {
  border-radius: 0 !important;
}

.header {
  position: sticky !important;
  top: 0;
  z-index: 1;
  margin-bottom: 2rem !important;
}

.active {
  // background-color: #a512eb;
  background-color: #4285f4;
}

.description {
  max-width: 300px;
  max-height: 390px;
  overflow-y: scroll;
}

.list {
  margin-left: -40px;
}
